.gpt-session-next {
    background: #FFFFFF;
    box-shadow: 0 .02rem .08rem rgba(176, 194, 255, 0.3);
    border-radius: .32rem;
    width: .48rem;
    height: .48rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: .2rem;
        height: .2rem;
        object-fit: contain;
    }
}
.copy.study-copy {
    top: -.44rem;
}
.nsp-session-nsp .card .study-content {
    word-break: break-word;
}
.nsp-exercise-answer .nsp-markdown p{
    white-space: pre-wrap;
}
