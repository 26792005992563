.nsp-result-loading-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.nsp-result-loading-bg {
  background: linear-gradient(to right,
    #D9D9D9 0%,
    #edeef1 10%,
    #D9D9D9 20%,
    #D9D9D9 100%
  );
  opacity: .15;
  background-size: 200% 100%;
  animation: nspTopicLoading 2s linear infinite;
  box-sizing: border-box;
}
  
.nsp-result-loading-text {
  margin: 0;
  height: .11rem;
  width: 100%;
  overflow: hidden;
}
.nsp-result-loading-text-last {
  width: 30%;
}
  
@keyframes nspTopicLoading {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

.nsp-loading-default {
  padding: 1.35rem 0;
  position: relative;
  background: #fff;
  width: 100%;
  z-index: 10;
  text-align: center;
  height: 100%;
  
  .loading-wrap {
    display: inline-flex;
    align-items: center;
    -moz-box-align: center;
    align-items: center;

    .loading {
      animation: circulation 1s infinite linear;
      animation: circulation 1s infinite linear;
    }
  }
}
  
@keyframes circulation {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.nsp-loading-text {
  .sentence {
    display: inline-block;
    vertical-align: middle;
    color: #000;
    font-weight: 400;
    max-width: 3rem;
    img {
      width: 0.16rem;
      height: 0.16rem;
      margin-right: 0.1rem;
      position: relative;
      top: 0.03rem;
      left: 0.03rem;
    }
    .arrow {
      top: 0.02rem;
      width: 0.14rem;
      height: 0.14rem;
    }
  }
  .gray-done {
    color: #979797;
  }
}
.nsp-loading-history-box {
  // transition: opacity .3s ease-in-out;
  position: relative;
  overflow: hidden;
}