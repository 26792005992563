.nps-ysjh {
  padding: 12px 16px 0 16px;
  background: #fff;
  border-radius: 0px 0px 12px 12px;
}

.nps-ysjh__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -9px;
}

.nps-ysjh__item {
  display: block;
  flex-grow: 1;
  width: 33%;
  box-sizing: border-box;
  padding-right: 9px;
  margin-bottom: 16px;
}

.nps-ysjh__imgWrap {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 134%;
  background: #f8f8f8;
  overflow: hidden;
  border-radius: 8px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(34, 34, 34, 0.03);
    pointer-events: none;
  }
}

.nps-ysjh__img {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: cover;
  object-position: center;
  background: #fff;
}

.nps-ysjh__title {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  margin-top: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nps-ysjh__subTitle {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #8F8F8F;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nps-ysjh__more {
  display: block;
  text-align: center;
  color: #205AEF;
  font-size: 14px;
  margin-top: -8px;
  padding: .14rem 0;
}
