.nsp-tips-box {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: .12rem 0 .16rem 0;
    background: rgba(243, 244, 247, 0.95);
    /* 输入背景框高斯模糊 */
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    max-width: 9.6rem;
    z-index: 99;
    overflow-x: hidden;
    .container {
        padding: 0 .12rem;
    }
    .stop-container {
        border-radius: .16rem;
        background: rgba(19, 108, 233, 0.07);
        margin: 0 0.12rem 0.16rem;
        padding: 0.08rem 0.12rem;
        color: #000;
        font-size: .14rem;
        .edit {
            color: #136CE9;
        }
        .split-line {
            height: .16rem;
            width: .01rem;
            display: inline-block;
            background: rgba(0, 0, 0, 0.14);
        }
    }
    .align-flex-end {
        align-self: flex-end;
    }
    .align-flex-center {
        align-self: center;
    }
    .meta-area {
        width: 100%;
    }
    .topic-input {
        overflow: hidden;
        border: 0;
        background: #FFFFFF;
        border-radius: .12rem;
        padding: .05rem .08rem .05rem .16rem;
        background: linear-gradient(90deg, rgba(32, 90, 239, 0) 0%, rgba(32, 90, 239, 0.05) 30.85%, rgba(32, 90, 239, 0) 100%);
        animation: nsp_textarea_bg 700ms linear forwards;
        background-repeat: no-repeat;
        background-position: -100% 0;
        background-size: 50% 100%;
        .createing {
            position: absolute;
            width: 100%;
            height: 0.4rem;
            background-color: transparent;
        }
        textarea {
            width: 100%;
            border: 0;
            resize: none;
            caret-color: #205AEF;
            outline: 1px solid transparent;
            border-width: 0;
            height: .32rem;
            line-height: .26rem;
            font-size: .16rem;
            color: #515151;
            padding: .06rem 0 0;
            vertical-align: middle;
            font-weight: 400;
            overflow: auto;
            animation: nsp_textarea_typing .8s steps(20, end) forwards;
        }        
        textarea::-ms-input-placeholder {
            padding-top: .01rem;
            color: #858585;
        }
        textarea::-webkit-input-placeholder {
            padding-top: .01rem;
            color: #858585;
        }
        textarea::placeholder {
            padding-top: .01rem;
            color: #858585;
        }
        .clear {
            font-size: .16rem;
            color: #d9d9d9;
            align-self: center;
        }
        .add-icon {
            width: 0.36rem;
            height: 0.36rem;
            z-index: 3;
        }
        .send-icon {
            width: 0.36rem;
            height: 0.36rem;
            z-index: 3;
            background-repeat: no-repeat;
            background-size: .36rem .36rem;
            background-position: center;
            background-repeat: no-repeat;
        }
        .send-loading-icon {
            width: 0.36rem;
            height: 0.36rem;
            z-index: 3;
        }
    }
    .topic-input-selected {
        border: 1.5px solid #205AEF;
    }
    .word-num {
        font-size: .1rem;
        color: #ACACAC;
    }
    .word-max {
        color: #205AEF;
    }
    .words-container {
        text-align: center;
    }
    .go-search {
        width: .48rem;
        height: .48rem;
        font-weight: 700;
        color: #c9c9c9;
        align-self: center;
    }
    .go-search-select {
        color: #205AEF;
    }
    .topic-box {
        width: 0.48rem;
        height: 0.48rem;
        background: #FFFFFF;
        border-radius: .12rem;
        border: 0;
        margin-right: 0.08rem;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: .18rem;
            color: #fff;
            font-weight: 700;
        }
        img {
            width: .24rem;
        }
    }
    .topic-box-select {
        background-color: rgba(19, 108, 233, 0.3);
    }
    .multimode-hide {
        height: 0;
        overflow: hidden;
    }
    .multimode {
        height: 1.07rem;
        transition: height .15s ease-in-out;
        padding: 0 .7rem .05rem;
        justify-content: space-around;
        .box {
            position: relative;
            align-self: center;
            text-align: center;
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 8px;
            background: #fff;
            img {
                padding: .18rem;
                width: .24rem;                
            }
            .input {
                position: absolute;
                right: 0;
                top: 0;
                opacity: 0;
                filter: alpha(opacity=0);
                cursor: pointer;
                height: 100%;
                width: 100%;
            }
            .text {
                color: #8f8f8f;
            }
        }
        .split-line {
            border-right: 1px solid rgba(0, 0, 0, 0.08);
            height: 0.43rem;
            width: 0;
            padding: 0;
        }
    }
    .pic-btns {
        margin: .12rem 0 .12rem .12rem;
        .btn {
            color: #242424;
            font-size: .14rem;
            background: rgba(0, 0, 0, .04);
            border-radius: .08rem;
            border: 0;
            padding: 0.08rem 0.12rem;
            cursor: pointer; 
        }
    }
    .rs-btns {
        margin: .04rem 0 0;
        .btn {
            color: #242424;
            font-size: .14rem;
            background: rgba(0, 0, 0, 0.04);
            border-radius: .08rem;
            border: 0;
            padding: 0.08rem 0.12rem;
            cursor: pointer; 
            display: inline-block;
        }
    }
    .topic-btns {
        overflow-x: scroll;
        scrollbar-width: none;
        scrollbar-color: transparent;
        width: 100%;
        .scroll-container {
            display: flex;
            overflow-x: scroll;
            .btn span {
                box-shadow: none;
                max-width: 2.68rem;
                white-space: nowrap;
                max-width: 2.68rem;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: unset;
            }
        }
    }
}

.nsp-search-box {
    margin-top: 0.18rem;
    background: #E4E7EF;
    margin-bottom: -0.16rem;
    display: flex;
    justify-content: center;
    .search-area {
        margin: .08rem 0 .32rem;
        justify-content: center;
        display: flex;
        align-items: center;
        color: #666;
    }
    .search-area-text {
        max-width: 2.68rem;
        font-weight: 600;
    }
    .search-icon {
        width: 0.24rem;
        height: 0.24rem;
    }
    .search-arrow-icon {
        width: 0.16rem;
        height: 0.16rem;
    }

}

@keyframes nsp_textarea_typing {
    0% {
        width: 0%
    }

    100% {
        width: 100%
    }
}

@keyframes nsp_textarea_bg {
    0% {
        background-position: -100% 0;
        background-color: #fff
    }

    100% {
        background-position: 200% 0;
        background-color: #fff
    }
}