.nsp-audio-btn {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    opacity: 0.8;
    border: 1px solid #E7E7E7;
    border-radius: .08rem;
    padding: .1rem;
    .text {
        color: #000;
        font-size: .14rem;
    }
    img {
        width: .12rem;
        height: .12rem;
    }
}
.nsp-audio-broad-cast {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
}
.nsp-audio-broad-cast-icon {
    display: inline-block;
    margin-left: 0.1rem;
    transform: rotate(180deg);
}
.nsp-audio-play-icon {
    width: .17rem;
    height: .24rem;
}
.nsp-audio-record-btn {
    border-radius: 12px;
    background: #FFF;
    border: 0;
    flex: 1;
    font-size: .16rem;
    line-height: .24rem;
    color: #242424;
    outline: none;
    border-width: 0;
    border-color: #FFF;
    border-style: none;
}
.nsp-audio-record-btn-gray {
    color: #8F8F8F;
}
.nsp-audio-mask {
    .container-audio {
        height: 2.63rem;
    }
    .audio-title {
        color: #242424;
        line-height: .22rem;
        font-size: .18rem;
        font-weight: 500;
        flex: 1;
        text-align: center;
    }
    .audio-close {
        position: absolute;
        right: 0.12rem;
    }
    .audio-close-icon {
        width: .13rem;
        color: #242424;
    }
    .audio-container {
        margin: .12rem .36rem .32rem;
        .tell {
            text-align: center;
            color: #515151;
        }
        .micor {
            margin-top: 0.32rem;
            text-align: center;
            img {
                width: .64rem;
                height: .64rem;
            }
        }
    }
    .record-container {
        margin: .12rem .2rem .24rem;
        overflow: hidden;
        text-align: center;
        .duration {
            font-weight: 500;
            color: #205AEF;
            padding: .03rem .04rem;
            font-size: .14rem;
            line-height: .12rem;
            background: #E6F0FF;
            border-radius: .04rem;
            display: inline-block;
        }
        .record-text {
            padding: 0.09rem 0;
        }
        .record-sound {
            max-height: 0.4rem;
            margin: 0.24rem 0 0.37rem;
            img {
                height: 100%;
                width: 100%;
                max-height: 0.4rem;
            }
        }
        .record-btn {
            width: 100%;
            color: #8F8F8F;
            background: #F8F8F8;
            border-radius: 8px;
            padding: 11px 0;
            border: 0;
            font-size: .14rem;
        }
        .record-active-btn {
            background: #205AEF;
            color: #fff;
        }
    }
}

.nsp-music-msg {
    margin: -0.08rem -0.16rem;
    border-radius: 0 .12rem 0 0;
    background: #F8FAFF;
    font-size: .16rem;
    line-height: .24rem;
    padding: .12rem .16rem;    
}
.nsp-music-container {
    .nsp-scroll-icon {
        background: #eee;
    }
    .nsp-scroll-icon-select {
        background: #b3b3b3;
    }
    .nsp-scroll {
        margin-top: 0;
        margin-bottom: .12rem;
        line-height: .06rem;
    }
    .nsp-music-reload-btn {
        border-radius: 8px;
        border: 1px solid #E7E7E7;
        opacity: 0.8;
        background: rgba(255, 255, 255, 0.70);
        display: flex;
        height: 36px;
        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #000;
    }
    .nsp-music-reload-btn-disable {
        color: #8F8F8F;
    }
    .row {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: .24rem;
        .wrap {
            flex: 1 1 auto;
            width: 50%;
            margin-bottom: .2rem;
        }
        .item-center {
            align-items: center;
            justify-items: center;
            overflow: hidden;
        }
        .title {
            text-align: left;
            max-width: 100%;
        }
        .content {
            width: 100%;
            text-align: left;
            overflow: hidden;
        }
    }
    .text {
        line-height: .16rem;
        color: #8f8f8f;
        max-width: 98%;
    }
    .text-gray {
        color: #8F8F8F;
    }
    .img-container {
        align-self: center;
        min-width: 0.36rem;
        width: .36rem;
        height: .36rem;
        border-radius: .06rem;
        margin-right: .06rem;
        overflow: hidden;
        position: relative;
        background-size: 100% 100%;
        background-color: #F8FAFF;
    }
    .img-success {
        width: .44rem;
        height: .44rem;
    }
    .text-container {
        align-self: baseline;
    }
    .img-success-bg {
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .audio-components {
        width: .01rem;
        height: .01rem;
        position: absolute;
        opacity: 0;
        bottom: 0;
    }
}