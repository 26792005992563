.study-container {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
}

.nsp-study-list {
    padding-top: .24rem;
    padding: .24rem .75rem 0;
    .inline-flex {
        display: inline-flex;
    }
}

.nsp-study-wrap {
    margin: 0 0 .16rem;
    padding: .18rem .12rem .13rem;
    box-sizing: border-box;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(19, 108, 233, 0.3);
    box-shadow: 0 .05rem .23rem rgba(86, 147, 204, 0.15);
    border-radius: .16rem;

    .nsp-container-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: .16rem;
        font-size: .16rem;
    }
}

.nsp-study-content {
    margin-bottom: .08rem;
    img {
        width: 100%;
        object-fit: cover;
    }
}