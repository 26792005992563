$airYou: #00bf5f !default;
$airLiang: #f8c732 !default;
$airQing: #f69731 !default;
$airZhong1: #ee5a31 !default;
$airZhong2: #dd1e2f !default;
$airZhong3: #920d19 !default;
$airZhong4: #57080f !default;
$airList: $airYou $airLiang $airQing $airZhong1 $airZhong2 $airZhong3 $airZhong4;
.nsp-weather {
  background-color: #fff;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #222;
  padding: 0.1rem 0.16rem;
  i {
    display: inline-block;
    height: 0.1rem;
    width: 1px;
    background-color: #E7E7E7;
    margin: 0 6px;
  }
  .header {
    display: flex;
    align-items: center;
    .city_name {
      font-weight: 501;
    }
    .from_info {
      font-size: 0.12rem;
      color: #979797;
    }
  }
  .warning {
    background: #F8F8F8;
    border-radius: 0.08rem;
    height: 0.28rem;
    line-height: 0.28rem;
    overflow: hidden;
    margin-top: 0.12rem;
    .warning-btn {
      display: flex;
      align-items: center;
      font-size: 0.12rem;
      line-height: 0.28rem;
      span {
        color: #fff;
        height: 0.16rem;
        width: 0.16rem;
        line-height: 0.16rem;
        border-radius: 0.04rem;
        font-size: 0.1rem;
        text-align: center;
        margin:0 0.06rem;
      }
      .type01 {
        background: #3767dc;
      }

      .type02 {
        background: #f5aa3e;
      }

      .type03 {
        background: #f25933;
      }

      .type04 {
        background: #c41423;
      }
    }
  }
  .today {
    margin-top: 0.12rem;
    padding: 12px 16px;
    background-color: #F0F8FF;
    border: 0.5px solid rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    .top_message {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info {
        .temperature {
          font-weight: 700;
          font-size: 0.56rem;
          line-height: 0.46rem;
          position: relative;
          &::after {
            content: '°';
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(100%);
            font-size: 0.28rem;
            font-weight: 400;
          }
          // margin-right: 0.12rem;
        }
      }
      .icon img{
        margin-top: -0.06rem;
      }
    }
    .more_message {
      white-space: nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
      margin-top: 0.09rem;
      span {
        position: relative;
        margin-right: 0.13rem;
        &::after {
          content: '';
          position: absolute;
          right: -0.06rem;
          top: 50%;
          height: 0.1rem;
          transform: translateY(-50%);
          background-color: #E7E7E7;
          width: 0.01rem;
        }
        &:nth-last-child(1) {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .hours {
    .title {
      color: #000;
      font-weight: 400;
      display: flex;
      align-items: center;
      line-height: 0.22rem;
      margin-top: 0.2rem;
      .timer_icon {
        margin-top: -0.01rem;
      }
    }
    .scroll_box {
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      display: -webkit-box;
      overflow: auto;
      .item {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 0.48rem;
        margin-right: 0.12rem;
        font-size: 0.14rem;
        padding-top: 0.08rem;
        span {
          &.time {
            color: #999;
          }
          &.level {
            color: #fff;
            width: 0.3rem;
            height: auto;
            line-height:  0.16rem;
            text-align: center;
            margin: 0.08rem 0 0.05rem;
            font-size: 0.12rem;
            border-radius: 0.04rem;
            @for $i from 1 to length($airList)+1 {
              &.type0#{$i} {
                background: nth($airList, $i);
              }
            }
          }
        }
        img {
          width: 0.21rem;
        }
        &.level {
          margin-right: 0;
        }
      }
    }
  }
  .link_url {
    text-align: center;
    margin-top: 0.1rem;
    line-height: 0.32rem;
    color: #205AEF;
  }
}

// 分享页面样式覆盖
.nsp-share-nsp{
  .nsp-session-custom{
    .nsp-weather{
      border-radius: 0.12rem;
      box-shadow: 0px 0.05rem 0.23rem 0px rgba(0, 0, 0, 0.06);
    }
  }
}
