.nsp-session-user-image {
    img {
        max-width: 2.84rem;
        max-height: 5.68rem;
        background-color: #FFFFFF;
        border-radius: .08rem;
    }
    .expired {
      margin-top: 0.06rem;
      display: flex;
      align-items: center;
      color: #B3B3B3;
      font-size: 0.12rem;
      line-height: 0.24rem;
      img {
          width: 0.14rem;
          height: 0.14rem;
          margin-right: 0.02rem;
      }
  }
}

.nsp-session-image {
    background: #FFFFFF;
    border-radius: 0 0 .12rem .12rem;
    padding: .16rem;
    min-height: 0.5rem;
    img {
        max-width: 100%;
        max-height: 100%;
        background-color: #FFFFFF;
        border-radius: .08rem;
    }
    @media screen and (min-width: 960px) {
        img {
            max-width: 2.9rem;
            align-self: center;
            max-height: 100%;
            background-color: #FFFFFF;
            border-radius: .08rem;
        }
    }
    .reload-btn {
        background: rgba(255, 255, 255, 0.7);
        opacity: 0.8;
        border: 1px solid #E7E7E7;
        border-radius: .08rem;
        padding: .07rem .12rem;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        color: #000;
    }

    .expired {
        margin-top: 0.06rem;
        display: flex;
        align-items: center;
        color: #B3B3B3;
        font-size: 0.12rem;
        line-height: 0.24rem;
        img {
            width: 0.14rem;
            height: 0.14rem;
            margin-right: 0.02rem;
        }
    }
}

.nsp-session-image-none-line-height {
    line-height: 0;
}

.nsp-session-image-viewer-animate {
    position: relative;
    img {
        transition: width .5s;
    }
}

.nsp-session-image-loading {
    position: absolute;
    top: 0;
    max-height: 1.8rem;
    overflow: hidden;
}

.nsp-session-image-viewer {
    position: fixed;
    width: 100%;
    min-height: 7rem;
    height: auto;
    background: #000;
    top: 0;
    bottom: 0;
    z-index: 999;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    img {
        max-width: 100%;
        transform: translate(0, 0) scale(1) rotate(0deg);
        width: 100%;
        border-radius: 0;
    }
}
@media screen and (min-width: 960px) {
    .nsp-session-image-viewer {
        align-items: center;
    }
    img {
        max-width: 4.26rem;
    }
}

.nsp-user-image-viewer-animate {
    position: relative;
    right: 0;
    line-height: 0;
    img {
        transition: width .5s;
    }
}
.nsp-user-image-viewer-animate-unset {
    img {
        transition: unset;
    }
}

.nsp-user-image-viewer {
    position: fixed;
    width: 100%;
    min-height: 7rem;
    height: auto;
    background: #000;
    top: 0;
    bottom: 0;
    z-index: 999;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
        transform: translate(0, 0) scale(1) rotate(0deg);
        width: 100%;
        border-radius: 0;
    }
}

.nsp-share-nsp {
    .nsp-session-image{
        padding: 0;
        box-shadow:none;
    }
    .nsp-session-user-image{
        margin-top: 0.04rem;
    }
}
