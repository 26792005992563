@import '@tencent/search-global-css/src/base/_variables.scss';
@import url('./common/style/index.scss');
@import url('./common/style/session.scss');
@import url('./common/style/loading.scss');
@import url('./common/style/talking.scss');
@import url('./common/style/mask.scss');
@import url('./common/style/noPermission.scss');
@import url('./common/style/betaWrap.scss');
@import url('./common/style/image.scss');
@import url('./common/style/feedBackMask.scss');
@import url('./common/style/study/index.scss');
@import url('./common/style/audio.scss');
@import url('./common/style/download.scss');

html, body {
    height: 100%;
    background: #F3F4F7;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
}


// table { border-collapse: collapse; }
// tr { border-bottom: solid 1px black; }
// tr:nth-child(even) {background-color: #f2f2f2;}
// th { border-right: solid 1px black; }
// td { border-right: solid 1px black; }

@supports (bottom: env(safe-area-inset-bottom)) {
    body {
        padding-bottom: 0;
        padding-bottom: env(safe-area-inset-bottom);
        padding-bottom: constant(safe-area-inset-bottom);
        bottom: env(safe-area-inset-bottom);
        background: #F3F4F7;
    }
}
#root {
    background: #F3F4F7;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 100%;
}
.App {
    max-width: 9.6rem;
    margin: 0 auto;
    min-height: 100%;
    background: #F3F4F7;
}

.nsp-header {
    text-align: center;
    align-items: center;
    width: 100%;
    color: #000;  
    i {
        font-size: .19rem;
        margin-right: .13rem;
        position: absolute;
        right: 0;
    }
}
@media screen and (min-width: 960px) {
    .App .nsp-header {
        padding-top: .4rem;
    }
}

.nsp-row {
    display: flex;
    .flex-full {
        flex: 1;
    }
}
.nsp-dir-column {
    flex-direction: column;
}

.nsp-ie-row {
    display: flex;
    align-items: center;
    .flex-full {
        flex: 1;
    }
    &-base-line {
        align-items: baseline;
    }
}
.nsp-ie-row-bottom {
    display: flex;
    align-items: flex-end;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}
.nsp-toast {
    .text {
        max-width: 3rem;
        font-weight: 600;
        font-size: .16rem;
        line-height: .24rem;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        background: #242424;
        border-radius: 8px;
        display: inline-block;
        padding: 0.12rem 0.16rem;
        margin: 0 auto;
    }
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 1.08rem;
    display: flex;
    align-items: center;
    right: 0;
    z-index: 999;
}

.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    line-height: 300px;
    text-align: center;
    font-size: 50px;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

::-webkit-scrollbar {
    display: none;
}
::-moz-scrollbar {
    display: none;
}
::-o-scrollbar {
    display: none;
}
::-ms-scrollbar {
    display: none;
}
