.nsp-knowledge-wrap {
    margin-top: .12rem;
}

.nsp-option-title {
    font-size: .12rem;
    color: #999999;
    line-height: .22rem;
    margin-bottom: .08rem;
}

.nsp-session-option {
    font-size: .14rem;
    line-height: .38rem;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 .05rem .23rem rgba(86, 147, 204, 0.15);
    border-radius: .20rem;
    margin-bottom: .08rem;
    padding: 0 .16rem;
    width: fit-content;
}

.nsp-session-options {
    display: flex;
    align-items: center;

    &>div {
        margin-top: .16rem;
        margin-bottom: .12rem;
    }

    .nsp-session-option {
        margin-right: .11rem;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
}

.nsp-excise-option {
    display: flex;
    margin-bottom: .16rem;
    div {
        width: .42rem;
        height: .4rem;
        background: rgba(255, 255, 255, 0.7);
        box-shadow: 0px .05rem .23rem rgba(86, 147, 204, 0.15);
        border-radius: .44rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: .08rem;
    }
}

.nsp-option-get-icon,
.nsp-option-noget-icon {
    width: .16rem;
    height: .16rem;
    object-fit: contain;
}

@keyframes topIn {
    from {
        transform: translateX(.10rem);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.nsp-study-point {
    animation-fill-mode: backwards !important;
}