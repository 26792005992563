.nsp-download {
  text-align: center;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  margin-top: .68rem;
  .btn {
    display: block;
    margin-top: .24rem;
    word-wrap: break-word;
    background: #205aef;
    border: 0;
    border-radius: 0.12rem;
    color: #fff;
    padding: 0.08rem 0.16rem;
    word-break: break-all;
    width: 2.68rem;
  }
}