.nsp-session-nsp,.nsp-session-user{
  img.checkbox {
    width: 0.2rem;
    height: 0.2rem;
    margin-top: 0.02rem;
    margin-left: 0.02rem;
    margin-right: 0.08rem;
  }
  img.disable {
    opacity: 0.3;
    pointer-events: none;
  }
} 
