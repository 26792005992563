.nsp-container {
    text-align: center;
    padding-top: .88rem;
    margin-left: .49rem;
    margin-right: .49rem;
    min-height: 90vh;
}
.nsp-container-title {
    font-weight: 600;
    margin-block-start: 0;
    margin-block-end: 0;
    img {
        width: .24rem;
        height: .24rem;
    }
}
.nsp-logo {
    width: 2rem;
    height: 2rem;
    background-color: transparent;
}

.nsp-link {
    color: inherit;
}

@media screen and (min-width: 960px) {
    .nsp-container {
        margin-top: .9rem;
    }
    .nsp-link {
        color: inherit;
        flex: 1 1 auto;
        width: calc(50% - 16px);
        margin-top: .2rem;
        margin-right: .08rem;
        margin-left: .08rem;
        text-align: center;
    }
    .nsp-swiper {
        display: flex;
        flex-wrap: wrap;
    }
    .nsp-container .nsp-tips {
        margin-top: .16rem;
        max-width: 5.7rem;
    }
}

.nsp-tips {
    margin-top: .24rem;
    .title-flex {
        align-items: center;
        display: flex;
        align-self: center;
        justify-content: center;
        vertical-align: middle;
        .inline-flex {
            display: inline-flex;
        }
    }
    .caption {
        font-weight: 300;
        color: rgba(0, 0, 0, 0.60);
    }
    .sub-search-icon {
        font-size: .12rem;
        font-weight: 600;
        align-self: center;
    }
    i {
        font-weight: 700;
    }
}
.nsp-arrow-left-icon {
    display: inline-block;
    font-size: .12rem;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    font-weight: 700;
}
.nsp-scroll {
    margin-top: .08rem;
    text-align: center;
    .nsp-scroll-icon {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #666;
        display: inline-block;
        border-radius: 50%;
        margin-right: .04rem;
    }
    .nsp-scroll-icon-select {
        background: #000;
        width: .12rem;
        height: .05rem;
        border-radius: .21rem;
    }
}
