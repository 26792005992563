.nsp-session {
    position: relative;
    min-height: 40vh;
    display: flex;
    flex-direction: column-reverse;
}

.nsp-session-new {
    position: sticky;
    position: -webkit-sticky;
    display: flex;
    flex-direction: column-reverse;
    bottom: .64rem;
}

.nsp-session-new-container {
    position: sticky;
    position: -webkit-sticky;
    align-self: flex-start;
}

.nsp-session-first {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    flex: 1;
    flex-direction: column;
}

.nsp-session-2 {
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    .container {
        height: calc(100vh - 190px);
        overflow-y: scroll;
        padding-bottom: 60px;
        padding-top: 30px;
        scroll-behavior: auto;
        scrollbar-width: thin;
        width: 100%;
        .box {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            margin: auto;
            width: 100%;
        }
        // .nsp-session > div {
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     padding: 6px 0;
        //     position: relative;
        // }
    }
}

.nsp-session-111 {
    display: flex;
    flex-direction: column-reverse;
    bottom: .66rem;
}

.nsp-session-new-container {
    position: sticky;
    position: -webkit-sticky;
    align-self: flex-start;
}

.nsp-session-first {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    flex: 1;
    flex-direction: column;
}

.nsp-session-2 {
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    .container {
        height: calc(100vh - 190px);
        overflow-y: scroll;
        padding-bottom: 60px;
        padding-top: 30px;
        scroll-behavior: auto;
        scrollbar-width: thin;
        width: 100%;
        .box {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            margin: auto;
            width: 100%;
        }
        // .nsp-session > div {
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     padding: 6px 0;
        //     position: relative;
        // }
    }
}

.nsp-session-111 {
    display: flex;
    flex: 1;
    justify-content: center;
    overflow: hidden;
}

.nsp-session-first {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    flex: 1;
    flex-direction: column;
}

.nsp-session-2 {
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    .container {
        height: calc(100vh - 190px);
        overflow-y: scroll;
        padding-bottom: 60px;
        padding-top: 30px;
        scroll-behavior: auto;
        scrollbar-width: thin;
        width: 100%;
        .box {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            margin: auto;
            width: 100%;
        }
        .nsp-session > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 6px 0;
            position: relative;
        }
    }
}

.nsp-session-111 {
    display: flex;
    flex: 1;
    justify-content: center;
    overflow: hidden;
}

.nsp-session-first {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    flex: 1;
    flex-direction: column;
}

.nsp-session-2 {
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    .container {
        height: calc(100vh - 190px);
        overflow-y: scroll;
        padding-bottom: 60px;
        padding-top: 30px;
        scroll-behavior: auto;
        scrollbar-width: thin;
        width: 100%;
        .box {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            margin: auto;
            width: 100%;
        }
        .nsp-session > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 6px 0;
            position: relative;
        }
    }
}

.nsp-session-1 {
    /* 开启flex布局 */
    display: flex;
    /* 将子元素布局方向修改为垂直排列 */
    flex-flow: column;
    /* 设置最小高度为当前视口，使不管中间部分有多高，始终都可以保持在底部 */
    height: calc(100vh - 1.4rem);
    overflow-y: scroll;
}
.nsp-session-1 > div {
    width: 100%;
    height: 200px;
}

.nsp-session-na-gap {
    padding-top: .77rem;
}

.nsp-session-user {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    .card-mask {
      position:absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
    .owner {
        color: #000;
        font-weight: 600;
    }
    .message {
      position:relative;
      display: flex;
      width: 100%;
      justify-content:space-between;
    }
    .card {
        background: #205AEF;
        color: #fff;
        padding: 0.08rem 0.16rem;
        border-radius: .12rem 0px .12rem .12rem;
        max-width: 80%;
        word-break: break-all;
        word-wrap: break-word;
        user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
    }
    // .card:active {
    //     background: #4289ED;
    // }
    div.copy-first {
        top: 1.6rem;
        z-index: 99;
    }
    .copy-arrow-first {
        top: -0.08rem;
        border: 0.04rem solid transparent;
        border-top: 0.04rem solid #fff;
        transform: rotate(180deg);
    }
}

.nsp-session .copy {
    display: none;
    position: fixed;
    padding: 0.12rem;
    background: #fff;
    border-radius: 8px;
    color: #242424;
    font-size: 0.16rem;
    font-weight: 600;
    top: -0.5rem;
    z-index: 1;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.08));
    font-size: .11rem;
    font-style: normal;
    .nsp-row {
      padding: 0.04rem;
    }
    .copy-icon {
        width: 0.2rem;
        height: 0.2rem;
        margin-right: .06rem;
    }
    span {
        line-height: .2rem;
    }
}
.nsp-session .copy-show {
    display: flex;
}
.nsp-session .copy-hide {
    display: none;
}
// .card:hover + .copy {
//     display: flex;
// }
.copy:hover {
    cursor: pointer;
}
.copy-nsp {
    left: .8rem;
}

.copy-arrow {
    position: absolute;
    bottom: -0.08rem;
    right: 50%;
    width: 0;
    height: 0;
    border: 0.04rem solid transparent;
    border-top: 0.04rem solid #fff;
}
.copy-arrow-user {
    right: 10%;
}

// .nsp-session-user .card:hover {
//     background: #4289ED;
// }

// .nsp-session-nsp .card:hover {
//     background: #F3F8FE;
// }

.nsp-session-nsp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-bottom: .44rem;
    .card-mask {
      position:absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
    .flex {
        display: flex;
    }
    .wrap {
        flex-wrap: wrap;
    }
    .pause-color {
        color: #979797;
    }
    .owner {
        color: #000;
        width: 100%;
        font-weight: 600;
        img {
            width: .24rem;
            height: .24rem;
        }

        .date {
            font-weight: normal;
            color: #CCC;
            font-size: 0.12rem;
            line-height: 0.2rem;
        }
    }
    .AI-icon {
        width: 0.24rem;
        height: 0.24rem;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .like-icon {
        width: .18rem;
        height: .18rem;
    }
    .pause-icon {
        width: .16rem;
        height: .16rem;
    }
    .pause-text {
        font-size: .14rem;
        line-height: .15rem;
        color: #333;
    }
    .card-disable {
        background: rgba(255, 255, 255, 0.44);
        opacity: .8;
    }
    .card {
        position: relative;
        background: rgba(255, 255, 255, 0.7);
        color: #242424;
        padding: 0.08rem 0.16rem;
        border-radius: 0px .12rem .12rem .12rem;
        width: calc(100% - 30px);
        max-width: 4.28rem;
        min-height: .72rem;
        transition: height .1s;
        -moz-transition: height .1s; /* Firefox 4 */
        -webkit-transition: height .1s; /* Safari and Chrome */
        -o-transition: height .1s;
        line-height: .28rem;
        user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        // .content .nsp-cursor > *:last-child::after, cursor {
        //     animation: nsp-cursor-animate 1s infinite step-start;
        //     content: '';
        //     height: .16rem;
        //     width: .02rem;
        //     margin-left: .04rem;
        //     background-color: #205AEF;;
        //     vertical-align: baseline;
        //     display: inline-block;
        //     position: relative;
        //     top: .03rem;
        // }
        .none-block-gap {
            margin-block-start: 0;
            margin-block-end: 0;
        }
        .nsp-cursor > :not(ol):not(ul):not(pre):not(strong):not(.search-result-tag-num):not(.search-result-tag):last-child:after,
        .nsp-cursor > :is(ol, ul):last-child > li:not(:has(*)):last-child::after,
        .nsp-cursor > :is(ol, ul):last-child > li:last-child > :last-child:after,
        .nsp-cursor > :is(p, div):last-child::after {
            animation: nsp-cursor-animate 1s infinite step-start;
            content: '';
            height: .16rem;
            width: .02rem;
            margin-left: .04rem;
            background-color: #205AEF;;
            vertical-align: baseline;
            display: inline-block;
            position: relative;
            top: .03rem;
        }
        .content > .content-show {
            word-break: break-all;
            overflow: hidden;
        }
        .content > .nsp-markdown {
            h1, h2, h3 {
                line-height: 1.2;
            }
            h2[id] {
                font-size: .14rem;
                font-weight: 400;
                margin-block-start: 0.06rem;
                margin-block-end: 0.06rem;
                margin-inline-start: 0;
                margin-inline-end: 0;
                display: block;
                line-height: 0.28rem;
            }
            del {
                text-decoration: none;
            }
            pre {
                background: #F3F6F9;
                border: 1px solid rgba(0, 0, 0, 0.04);
                border-radius: .08rem;
                white-space: pre;
                margin: .08rem 0 .16rem;
                overflow-x: scroll;
                overflow-y: hidden;
                padding: 0.08rem 0.12rem;
                code {
                    background: #F3F6F9;
                }
            }
            code {
                border-radius: .08rem;
                background: #F3F6F9;
                padding: .04rem;
            }
            img {
                width: 90%;
                border-radius: 0.08rem;
                display: none;
            }
            blockquote {
                border-left: 2px solid #205AEF;
                padding-left: 0.08rem;
            }
            q::before {
                content: open-quote;
            }
            q::after {
                content: close-quote;
            }
            a {
                color: #205AEF;
            }
            code, pre {
                margin-block-start: 0;
                margin-block-end: 0;
            }
            p {
                margin-block-start: .06rem;
                margin-block-end: .06rem;
            }
            .katex  {
                width: 100%;
                word-break: keep-all;
                word-wrap: normal;
                white-space: normal;
                p {
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                }
            }
            table {
                margin: .12rem .01rem .16rem;
                // border-radius: .08rem;
                /* 消除单元格之间的空隙 */
                border-collapse: collapse;
                border: 1px solid #C9CBCD;
                border-radius: .08rem;
                 /* 消除掉外边框 */
                border-style: hidden;
                /* hack一下加个阴影 */
                box-shadow: 0 0 0 1px #C9CBCD; 
                display: -moz-box;
                display: -webkit-box;
                // display: block;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                overflow-x: scroll;
                width: max-content;
                // table-layout: fixed;/**固定table表格**/
                z-index: 11;
                // overflow: hidden;
            }
            pre::-webkit-scrollbar, .nsp-markdown-table::-webkit-scrollbar {
                display: block;
            }
            pre::-moz-scrollbar, .nsp-markdown-table::-moz-scrollbar {
                display: block;
            }
            pre::-o-scrollbar, .nsp-markdown-table::-o-scrollbar {
                display: block;
            }
            pre::-ms-scrollbar, .nsp-markdown-table::-ms-scrollbar {
                display: block;
            }
            pre::-webkit-scrollbar, .nsp-markdown-table::-webkit-scrollbar {
                width: .1rem;
                height: .05rem;
            }
            pre::-webkit-scrollbar-track, .nsp-markdown-table::-webkit-scrollbar-track {
                background-color: transparent;
            }
          
            pre::-webkit-scrollbar-thumb,.nsp-markdown-table::-webkit-scrollbar-thumb {
                background: #C9CBCD;
                border-radius: .25rem;
            }
            table thead {
                font-weight: 500;
                th {
                    background: #fff;
                }
            }
            table tbody {
                overflow-x: scroll;
            }
            td, th {
                text-align: center;
                padding: .08rem .12rem;
                min-width: 1rem;
                max-width: 1.4rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                // word-wrap: break-word;
                // word-break: break-all;
                overflow: hidden;
                border-right: 1px solid rgba(0, 0, 0, 0.08);
            }
            tbody td:last-child, th:last-child {
                border-right: 0;
            }
            tr:nth-child(odd) {
                background: #f8f8f8;
            }
            tr:nth-child(even) {
                background: #fff;
            }
            ul, ol {
                display: block;
                list-style-type: decimal;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 20px;
                li {
                    display: list-item;
                    text-align: -webkit-match-parent;
                    list-style: none;
                    font-size: .14rem;
                }
            }
        }
        .content > .nsp-markdown-done {
            img {
                display: inline-block;
                max-width: 90%;
            }
            td, th {
                overflow: auto;
                word-wrap: break-word;
                word-break: break-all;
                text-overflow: unset;
                white-space: pre-wrap;
            }
        }
        .vote-container {
            position: absolute;
            right: 0;
            bottom: -0.32rem;
            width: 100%;
            display: flex;
        }
        .number-top {
            display: inline-block;
            width: .23rem;
            height: .24rem;
        }
        .search-result-tag-num {
            color: #666;
            font-size: .1rem;
            line-height: .12rem;
            font-weight: 700;
            padding: 0.01rem 0.04rem;
            background: #eee;
            border-radius: 0.04rem;
            margin-right: 0.04rem;
            margin-left: 0.04rem;
            font-style: normal;
            position: relative;
            bottom: 0.02rem;
        }
        .search-result-tag-num:hover {
            cursor: pointer;
        }
    }
    .copy-tips {
        top: -.8rem;
    }
    // .card:hover {
    //     .copy {
    //         display: flex;
    //     }
    // }
    // .card:active {
    //     background: rgba(255, 255, 255, 0.5);
    // }
    .card-auto-height {
        min-height: 0;
    }
}
.nsp-source {
    color: #666;
}
.nsp-source:hover {
    cursor: pointer;
}
.nsp-source-sort {
    width: .14rem;
    height: .14rem;
    border-radius: .04rem;
    overflow: hidden;
    background: #eee;
    padding: .01rem .04rem;
    font-weight: 700;
    font-size: .1rem;
    color: #666;
}
.nsp-source-img {
    width: .16rem;
    height: .16rem;
    display: inline-block;
    img {
        width: 100%;
        border-radius: 50%;
        position: relative;
    }
}
.nsp-source-name {
    font-size: .12rem;
    position: relative;
    top: .01rem;
}

.nsp-session-bottom {
    width: 100%;
    height: 1.87rem;
}

.nsp-session-bottom-focus {
    height: 4.51rem;
}

.nsp-session-nums {
    font-size: .12rem;
    line-height: .2rem;
    color: #979797;
    flex: 1;
    margin-top: 0;
    vertical-align: middle;
    align-self: center;
}

.nsp-error-tips {
    color: #979797;
    margin: .37rem 0;
    width: 100%;
    .tips {
        text-align: center;
        line-height: .22rem;
        font-size: .12rem;
        flex: 1;
    }
    .line {
        display: inline-block;
        width: 0.22rem;
        height: 0.01rem;
        background: rgba(154, 154, 154, 0.51);
        position: relative;
        bottom: .03rem;
    }
}
.nsp-session-custom {
    margin: .08rem -0.16rem -0.08rem -0.16rem;
    * {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    em,i {
        font-style: normal;
    }
}
.nsp-session-custom>div:first-child {
    border-radius: 0 0 .12rem .12rem;
}

@-webkit-keyframes nsp-cursor-animate {
    0% { width: 0;}
    20% { width: 0;}
    33% { width: .03rem;}
    66% { width: .03rem;}
    100% { width: 0;}
}

@keyframes nsp-cursor-animate {
    0% { width: 0;}
    33% { width: .03rem;}
    66% { width: .03rem;}
    100% { width: 0;}
}

/* box-shadow+animate方法  */
.nsp-dot {
    font-family: simsun;
}
:root .nsp-dot { /* 这里使用Hack是因为IE6~IE8浏览器下， vertical-align解析不规范，值为bottom或其他会改变按钮的实际高度*/
    display: inline-block;
    width: .18rem;
    vertical-align: bottom;
    overflow: hidden;
    position: relative;
    bottom: .02rem;
    left: .02rem;
}
@-webkit-keyframes dot {
    0% { width: 0; margin-right: .12rem; }
    33% { width: .04rem; margin-right: .08rem; }
    66% { width: .08rem; margin-right: .04rem; }
    100% { width: .12rem; margin-right: 0;}
}
.nsp-dot {
    -webkit-animation: dot 2s infinite step-start;
}

.nsp-session-debug {
    overflow: hidden;
    word-break: break-all;
    margin-top: 0.08rem;
    padding-top: 0.08rem;
    background: #E8F1FF;
    border-radius: .08rem;
    code {
        margin-top: 0.18rem;
    }
}

.nsp-session-debug-btn {
    color: #000;
    font-size: 0.14rem;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 5px 23px rgba(86, 147, 204, 0.15);
    border-radius: 16px;
    border: 0;
    padding: 0.08rem 0.12rem;
    cursor: pointer;
}

@keyframes dot {
    0% { width: 0; margin-right: .12rem; }
    33% { width: .04rem; margin-right: .08rem; }
    66% { width: .08rem; margin-right: .04rem; }
    100% { width: .12rem; margin-right: 0;}
}
.nsp-dot {
    animation: dot 2s infinite step-start;
}
.gofeedback-icon {
    width: .12rem;
    height: .12rem;
    position: absolute;
    top: 25%;
}
.feedback {
    display: none;
    position: absolute;
    padding: 0.12rem 0.16rem;
    background: #242424;
    border-radius: 0.08rem;
    z-index: 2;
    color: #fff;
    font-size: .16rem;
    font-weight: 600;
    left: 0.34rem;
    top: 0.3rem;
    .feedback-arrow {
        position: absolute;
        top: -0.14rem;
        right: 40%;
        width: 0;
        height: 0;
        border: .08rem solid transparent;
        border-bottom: .08rem solid #242424;
    }
    .feedback-icon{
        width: 0.2rem;
        height: 0.2rem;
        margin-right: .06rem;
    }
    span {
        height: 0.2rem;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 0.16rem;
        line-height: 0.20rem;
        display: flex;
        align-items: center;
        color: #FFFFFF;
    }
}
.feedback:hover {
    cursor: pointer;
}


.nsp-share-bar {
  z-index: 200;
  position:fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  // max-width: 9.6rem;
  padding: 0.2rem 0;

  // front
  color: #181919;
  font-size: 0.16rem;
  line-height: 0.24rem;
  height: 0.36rem;

  border-top: 1px solid #F1F1F1;
  background: #FFF;

  .nsp-row {
    padding: 0 0.2rem;
    width: 100%;
    max-width: 9.2rem;
  }

  .btn-share {
    display: flex;
    width: 0.54rem;
    height: 0.24rem;
    padding: 0.06rem 0.12rem;
    justify-content: center;
    align-items: center;

    border-radius: 12px;
    background: #F8F8F8;

    &.disable{
        opacity: 0.4;
        pointer-events: none;
    }

    img {
      width: 0.18rem;
      height: 0.18rem;
      margin-right: 0.04rem;
    }
  }

  .btn-cancel{
    width: 0.32rem;
    height: 0.24rem;
    margin-top: 0.06rem;
  }
}

.nsp-share-window{
  // z-index: 99;
  position:absolute;
  bottom: 0;
  width: 100%;
  // max-width: 9.6rem;

  border-radius: 12px 12px 0px 0px;
  background: var(--bg-grey, #F6F7FA);

  .top {
    height: 0.98rem;
    display: flex;
    justify-content: space-around;
    padding: 0.3rem 0.3rem 0 0.3rem;

    color: var(--a-2, #666);
    text-align: center;
    font-size: 0.11rem;
    line-height: 0.15rem;

    .item{
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 0.08rem;
      .icon{
        width: 0.55rem;
        height: 0.55rem;
        border-radius: 14px;
        background: var(--bg-white, #FFF);
        box-shadow: 0px 19px 19px 0px rgba(236, 238, 242, 0.80);
        img{
          margin-top: 0.16rem;
          width: 0.24rem;
          height: 0.24rem;
        }
      }
    }
  }
  .bottom {
    height: 0.22rem;
    border-top: solid 0.5px #EEE;
    margin: 0 0.1rem;
    padding: 0.17rem 0 0.33rem;

    color: var(--a-2, #666);
    text-align: center;
    font-size: 0.18rem;
    line-height: 0.22rem;
  }
}

.nsp-fixed-top-gradient-box {
    z-index: 500;
    position: fixed;
    top: .44rem;
    width: 100%;
    height: 0.33rem;
    background: linear-gradient(180deg, #F3F4F7 0%, rgba(243, 244, 247, 0.00) 100%);
    .nsp-back-na {
        display: flex;
        position: relative;
        justify-content: center;
        background: #fff;
        border-radius: 50%;
        width: 0.36rem;
        height: 0.36rem;
        margin-left: .12rem;
        top: .15rem;
        align-items: center;
        box-shadow: 0px 2px 8px rgba(182, 148, 255, 0.2);
        i {
            color: #000;
            background-color: transparent;
            font-weight: 700;
        }
    }
}

.nsp-fixed-top-box {
    z-index: 800;
    position: fixed;
    top: 0;
    width: 100%;
    height: 0.44rem;
    background: #f3f4f7;
}
