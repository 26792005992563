.nsp-fb-mask-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(34, 34, 34, 0.5);
  z-index: 600;
  .pc-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    border-radius: 0.16rem 0.16rem 0 0;
    width: 100%;
    max-height: 690px;
    overflow-y: auto;
  }
  .container {
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    border-top-left-radius: 0.16rem;
    border-top-right-radius: 0.16rem;
    height: auto;
  }
  @media screen and (min-width: 640px) {
    .container {
      position: absolute;
      bottom: 0;
      background: #fff;
      width: 100%;
      border-top-left-radius: 0.16rem;
      border-top-right-radius: 0.16rem;
      height: 395px;
    }
  }
  .header {
    height: 0.48rem;
    width: 100%;
    align-items: center;
    .title {
      font-size: 0.18rem;
      line-height: 0.22rem;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      color: #242424;
      display: flex;
      justify-content: center;
      flex: 2;
    }
    i {
      color: #95959e;
      font-size: 0.16rem;
    }
    .close-icon {
      color: #242424;
      width: 0.24rem;
    }
    .back {
      text-align: left;
    }
  }
  .error-tips {
    background-color: #ddd;
    color: #fff;
    padding: 0.2rem;
    text-align: center;
    line-height: 0.2rem;
  }
  .nsp-fb-content-box {
    // height: 100%;
    margin: 0 0.2rem;
    max-height: 4rem;
    overflow-y: auto;
    .nsp-fb-content {
      margin: 0.16rem 0;
    }
    .sub-title {
      font-size: 0.16rem;
      line-height: 0.24rem;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      color: #242424;
      display: flex;
      align-items: center;
      margin-bottom: 0.08rem;
    }
    .fb-item {
      display: inline-block;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 0.14rem;
      line-height: 0.18rem;
      color: rgba(0, 0, 0, 0.6);
      padding: 0.11rem 0.12rem;
      margin: 0.04rem 0.08rem 0.04rem 0;
      cursor: pointer;
      &.clicked {
        background: #f3f8fe;
        color: #205AEF;
      }
    }
  }
  .fb-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 0.4rem;
    gap: 0.04rem;
    isolation: isolate;
    height: 0.4rem;

    background: #205AEF;
    color: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  textarea {
    width: -webkit-fill-available;
    width: -moz-available;
    resize: none;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    caret-color: #205AEF;
    outline: 1px solid transparent;
    border-width: 0;
    overflow: hidden;
    padding: 0.11rem 0.2rem 0.11rem 0.12rem;
    vertical-align: middle;
    font-weight: 400;
    order: 1;
    flex-grow: 0;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 0.14rem;
    line-height: 0.21rem;
  }
  textarea::-ms-input-placeholder {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 0.14rem;
    line-height: 0.21rem;

    color: rgba(0, 0, 0, 0.44);
  }
  textarea::-webkit-input-placeholder {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 0.14rem;
    line-height: 0.21rem;

    color: rgba(0, 0, 0, 0.44);
  }
  textarea::placeholder {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 0.21rem;

    color: rgba(0, 0, 0, 0.44);
  }
}
.close-box {
  position: absolute;
  right: 12px;
  top: 10px;
}
.fixed-body {
  overflow: hidden;
}
.nsp-fb-qr {
  border-radius: 8px;
  background: #F5F5F5;
  align-self: center;
  align-items: center;
  img {
    width: 0.88rem;
    padding: 0.08rem;
  }
}
.gray-text {
  color: rgba(0, 0, 0, 0.60);
}
.nsp-fb-success {
  margin: .24rem .2rem;
  border-radius: 8px;
  background: #F5F5F5;
  .qr-container {
    padding: .36rem .2rem;
    text-align: center;
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    .qr-img {
      width: 1.7rem;
      height: 1.7rem;
    }
    .title-big {
      font-weight: 500;
    }
  }
}