@import '@tencent/search-global-css/src/base/_variables.scss';

.saSwiper {
  overflow: hidden;

  .swiperContainer {
    touch-action: pan-y;
    display: flex;
    height: 100%;

    .swiperItem {
      display: inline-block;
      height: 100%;
    }
  }

  .swiperDots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $sa-spacing-6;

    i {
      margin: 0 $sa-spacing-2;
      width: $sa-spacing-4;
      height: $sa-spacing-4;
      font-size: 0;
      line-height: 0;
      border-radius: $sa-radius-round;
      background: #f2f2f2;

      &.active {
        background: #126ce9;
        width: $sa-spacing-12;
        border-radius: $sa-spacing-4;
      }
    }
  }
}
