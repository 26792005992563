.nps-ys {
  padding: 12px 16px 0 16px;
  background: #fff;
  border-radius: 0px 0px 12px 12px;
}
.nps-ys__banner {
  position: relative;
  margin-bottom: 12px;
}

.nps-ys__bannerLink {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 55%;
  background: #f8f8f8;
  overflow: hidden;
  border-radius: 8px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(34, 34, 34, 0.03);
    pointer-events: none;
  }
}

.nps-ys__bannerImg {
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: cover;
  object-position: center;
  background: #fff;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.nps-ys__playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.nps-ys__playIconSmall {
  width: 10px;
  margin-right: 3px;
  position: relative;
  top: 2px;
}

.nps-ys__summary {
  display: flex;
}

.nps-ys__summary__left {
  width: 66px;
  margin-right: 12px;
  height: 100%;
}

.nps-ys__summary__imgWrap {
  padding-bottom: 133%;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 0;
  background: #f8f8f8;
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(34, 34, 34, 0.03);
    pointer-events: none;
  }
}

.nps-ys__summary__img {
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: cover;
  object-position: center;
  background: #fff;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.nps-ys__summary__score {
  color: #ff8a14;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  right: 4px;
  top: 2px;
  z-index: 10;
}

.nps-ys__summary__title {
  color: #242424;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  em {
    font-style: normal;
  }
}

.nps-ys__summary__sta {
  font-size: 12px;
  line-height: 18px;
  color: #666;
  margin-top: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nps-ys__summary__type {
  font-size: 12px;
  line-height: 18px;
  color: #666;
  margin-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nps-ys__summary__right {
  flex: 1;
  overflow: hidden;
}

.nps-ys__summary__tag {
  color: #666;
  background: #f8f8f8;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
  margin-right: 8px;
  padding: 0 4px;
}

.nps-ys__more {
  display: block;
  text-align: center;
  margin-top: 8px;
  padding: .14rem 0;
  vertical-align: middle;
  color: #205AEF;
  font-size: 14px;
}

.nps-ys__summary__icon {
  display: inline-block;
  margin-right: 4px;
  width: 12px;
  height: 12px;
  position: relative;
  top: 2px;
}

.nps-ys__btn {
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  background: rgba(136, 136, 136, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  z-index: 2;
  text-align: center;
  padding: 3px 8px;
}


.nsp-share-nsp{
  .nps-ys{
    padding:0;
  }
}

