.beta-wrap {
  position: absolute;
  width: 297px;
  height: 244px;
  left: 50%;
  margin-left: -149px;
  top: 222px;

  background: linear-gradient(120.18deg, #ffffff 3.09%, #e6f3ff 102.55%);
  border-radius: 24px;
  z-index: 999;
}
.mask {
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4) !important;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: auto;
  position: fixed;
}
.beta-wrap-title {
  position: absolute;
  width: 100%;
  height: 24px;
  top: 6px;

  justify-content: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  color: #000000;
}
.beta-wrap-content {
  position: absolute;
  width: 241px;
  height: 96px;
  left: 28px;
  top: 40px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  color: rgba(0, 0, 0, 0.7);
}
.beta-wrap-button {
  position: absolute;
  width: 203px;
  height: 40px;
  left: 47px;
  top: 165px;

  background: #205AEF;
  border-radius: 24px;
  z-index: 999;
}
.beta-wrap-button-button-text {
  position: absolute;
  width: 128px;
  left: 27px;
  top: 4px;
  padding: .04rem 0;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #ffffff;
}
.beta-wrap-button-vetor {
  position: absolute;
  left: 81.28%;
  right: 12.81%;
  top: 40%;
  bottom: 40%;

  border: 1px solid #ffffff;
}
.beta-wrap-button-arrow-left-icon {
  position: absolute;
  left: 81.28%;
  right: 12.81%;
  top: 40%;
  bottom: 40%;
  color: #ffffff;
  display: inline-block;
  font-size: 0.12rem;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  font-weight: 700;
}
