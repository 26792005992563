.nsp-study-qustion-card {
    margin: .52rem .24rem .18rem;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 .05rem .23rem rgba(86, 147, 204, 0.15);
    border-radius: .12rem;
    border: 1px solid #205AEF;
    padding: .1rem .16rem;
    font-size: .16rem;
}

.nsp-study-question-content {
    line-height: .24rem;
}

.nsp-study-translate-content {
    line-height: .24rem;

    .nsp-study-translate-sentence:nth-child(even) {
        color: #999999;
    }
}

.nsp-study-paratext {
    font-size: .12rem;
    line-height: .22rem;
    color: #979797;
    text-align: center;
}

.nsp-study-question-btn {
    background: rgba(32, 90, 239, 0.05);
    line-height: .32rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: .2rem;
    width: .97rem;
    font-size: .14rem;
}

.nsp-study-translate-desc {
    display: flex;
    align-items: center;
    margin-top: .16rem;
    justify-content: space-between;
}

.nsp-study-word-box {
    width: calc(100% - .32rem);
    background: #FFFFFF;
    box-shadow: 0 0 .25rem rgba(86, 126, 204, 0.2);
    border-radius: .12rem;
    position: absolute;
    padding: .24rem .21rem .2rem;
    top: 3.24rem;
    left: .16rem;
    box-sizing: border-box;
    z-index: 3;
}

.nsp-study-word-title {
    color: #242424;
    font-weight: bold;
    font-size: .18rem;
    line-height: .26rem;
    margin-bottom: .05rem;
}

.nsp-study-word-pronounce {
    display: flex;
    font-size: .12rem;
    margin-bottom: .11rem;

    .item {
        background: #F8F8F8;
        backdrop-filter: blur(.025rem);
        border-radius: .335rem;
        line-height: .30rem;
        margin-right: .09rem;
        display: flex;
        align-items: center;
        padding: 0 .10rem;
    }
}

.nsp-study-word-paraphrase {
    margin-bottom: .12rem;
    line-height: .24rem;
    font-size: .14rem;
}

.nsp-study-paraphrase-txt {
    background: rgba(19, 108, 233, 0.1);
    border-radius: .04rem;
    padding: 0 .04rem;
    color: #205AEF;
    margin-right: .04rem;
    font-size: .11rem;
    line-height: .18rem;
    display: inline-block;
}

.nsp-study-result-btn {
    font-size: 0.14rem;
    font-weight: bold;
    text-align: center;
    line-height: .4rem;
    background: #F8F8F8;
    border-radius: .08rem;
    backdrop-filter: blur(2.5px);
    color: #222222;
}

.nsp-study-triangle {
    position: absolute;
    width: .15rem;
    height: .15rem;
    left: 1.59rem;
    transform: rotate(45deg);
    background: #FFFFFF;
    border-radius: 2px 0;

    // transform: matrix(-1, 0, 0, 1, 0, 0);
    &.top {
        bottom: -.08rem
    }

    &.bottom {
        top: -.08rem;
    }
}

.active-word {
    background: #146CE9;
    color: #fff;
}

.nsp-study-translate-icon {
    width: .14rem;
    height: .14rem;
    object-fit: contain;
}

.gpt-pronounce-icon {
    width: .16rem;
    height: .16rem;
    object-fit: contain;
    cursor: pointer;
}

.mask-translate-bg {
    position: fixed;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0);
    z-index: 2;
}

.nsp-study-btn-disabled {
    color: #979797;
}

.nsp-study-translate-fail {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: .14rem;
}
.nsp-study-fail-title{
    width: 100%;
    text-align: left;
    font-size: .16rem;
}
.nsp-study-fail-txt {
    color: #999;
    margin-top: .06rem;
    margin-bottom: .08rem;
    line-height: .24rem;
}

.nsp-study-fail-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: .3rem;
    background: rgba(255, 255, 255, 0.7);
    opacity: 0.8;
    border: 1px solid #E7E7E7;
    border-radius: .08rem;
    padding: 0 .12rem;
    color: #000000;
    font-weight: 500;
}
.gpt-refresh-icon {
    width: .12rem;
    height: .12rem;
    object-fit: contain;
}
.enter-icon {
    width: 0.26rem;
    height: 0.26rem;
    z-index: 3;
    opacity: 0.3;
}
.enter-icon-active {
    opacity: 1;
}