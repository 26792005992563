.nsp-mask-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(34, 34, 34, 0.5);
    z-index: 600;
    .container {
        position: absolute;
        bottom: 0;
        background: #fff;
        width: 100%;
        border-top-left-radius: 0.16rem;
        border-top-right-radius: 0.16rem;
        height: 90%;
    }
    .header {
        height: 0.48rem;
        width: 100%;
        align-items: center;
        i {
            color: #95959E;
            font-size: .16rem;
        }
        .close-icon {
            width: .24rem;
        }
        .back {
            text-align: left;
        }
        .target-page {
            display: inline-block;
        }
    }
    .error-tips {
        background-color: #DDD;
        color: #fff;
        padding: 0.2rem;
        text-align: center;
        line-height: .2rem;
    }
    iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
}
  